@import "../../scss/variables";

.trait-list {
    li {
        list-style-type: disc;
        margin: 12px 0;
    }
}

.traits-tabs {
    align-items: flex-end;
    height: 60px;
}

.traits-tab-container {
    height: calc(100% - 60px);
    border-top: $border;
}

.two-column-tab {
    display: flex;
    .left-panel {
        border-right: $border;
        flex: 2;
        background-color: white;
    }
    .right-panel {
        flex: 5;
    }
}
