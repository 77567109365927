@import "./variables";

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.transition-checked-rotate-180 {
    transition-property: all;
    transition-duration: 300ms;
    &.checked {
        transform: rotate(180deg);
    }
}

.transition-checked-rotate-145 {
    transition-property: all;
    transition-duration: 300ms;
    &.checked {
        transform: rotate(225deg);
    }
}

.transition-fade-out {
    animation: fadeOut ease 500ms;
    animation-fill-mode: forwards;
}

.transition-blink {
    animation: blinker 750ms linear infinite;
    animation-iteration-count: 4;
}
