@import "../../scss/variables";

.rating-step {
    .rating-body {
        padding-right: 2rem;
    }
}

.action-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    border-left: $border;
    border-left-style: dotted;
    padding-left: 1rem;
}

.finish-assessment {
    display: flex;
    align-items: stretch;
    height: 100%;
    justify-content: stretch;
    .action-plan {
        min-height: 200px;
        overflow: auto;
        //border: $border;
        border-radius: 5px;
    }
}

.rating-section {
    h2 {
        font-size: 1.3rem;
        font-weight: lighter;
    }
}

.trait-rating-card {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 10px;
    border: $border;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    height: 60px;
    border: 6px double lightgrey;
    min-width: 190px;
    max-width: 250px;
    cursor: pointer;
    &.selected {
        box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    }
    .trait-card {
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .slider-label {
            position: absolute;
            font-size: 0.8rem;
            border-radius: 20%;
            border: 2px solid;
            text-align: center;
            width: 32px;
            padding: 2px 0;
            top: -12px;
            right: -12px;
            background-color: white;
        }
    }
    .rating-button {
        border-radius: 10px;
        border: $border;
        height: 32px;
        width: 32px;
        cursor: pointer;
        &:hover {
            border-color: $primary;
        }
        &.checked {
            box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
        }
    }
}

// TODO You've duplicated this in the FormStepper folder, clean it up
.assessment-stepper {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: stretch;
    min-height: 0;
    .modal-body {
        overflow: auto;
        min-height: 0;
    }
    .stepper-inner-container {
        flex: 1;
        display: flex;
        align-items: stretch;
        min-height: 0;
    }
    .step-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        flex: 1;
    }
    .step {
        display: flex;
        flex: 2;
        flex-direction: column;
    }
    textarea {
        height: 150px;
    }
    .form-control {
        font-size: 0.9rem;
    }

    .step-body {
        padding: 1rem 2rem;
        flex: 1;
    }

    h5 {
        font-size: 1rem;
    }

    .stepper-nav {
        flex: 1;
    }

    .custom-field-row {
        .col:nth-child(even) {
            padding-left: 1rem;
        }
    }

    .stepper-nav-items {
        display: flex;
        position: relative;
        .stepper-nav-item {
            max-height: 60px;
            display: flex;
            flex: 1;
            justify-content: space-between;
            padding: 0 1.5rem;
            align-items: center;
            font-size: 1.1rem;
            .stepper-nav-item-label {
                font-weight: 600;
                color: #767691;
                line-height: 19px;
                font-size: 14px;
                text-align: center;
                padding: 16px 0;
            }
            &.stepper-nav-item-current {
                background-color: $panel-background;
                .stepper-nav-item-label {
                    font-weight: bold;
                    color: #2b376e;
                }
            }
            &.last-step {
                &:not(.stepper-nav-item-current) {
                    //background-color: white;
                    border-top: double 1px $primary;
                    border-bottom: double 1px $primary;
                    .stepper-nav-item-label {
                        font-weight: bold;
                        color: $primary;
                    }
                }
                &.stepper-nav-item-current {
                    background-color: $primary;
                    .stepper-nav-item-label {
                        font-weight: bold;
                        color: white;
                    }
                }
            }
        }
    }
    .body-padding {
        padding-bottom: 71px;
    }
    .stepper-footer {
        flex: 1;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 71px;
        display: flex;
        justify-content: right;
        border-top: $border;
        padding: 1rem;
        > .btn {
            margin-right: 6px;
        }
    }
}
