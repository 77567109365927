@import "../../scss/variables";

ul {
    padding-left: 0;
}

li {
    list-style-type: none;
}

.side-area-section.employee-rating-status {
    .assessment-line {
        display: flex;
        align-items: center;
        > * {
            margin-left: 12px;
            &.done {
                color: $success;
            }
            &.requested {
                color: $yellow;
            }
            &.danger {
                color: $danger;
            }
            &.warning {
                color: $yellow;
            }
        }
        span {
            font-size: 0.9rem;
        }
    }
}

.mapping-level-table {
    .level-label {
        font-weight: bold;
        font-size: 1.1rem;
    }
    .select-col {
        width: 30px;
        &.child {
            padding-left: 30px;
        }
    }
    .progress-col {
        width: 30px;
        padding-right: 20px;
    }
    .expand-col {
        width: 30px;
    }
    .level-row {
        height: 3rem;
        border: none;
        border-bottom: $border;
        &.selected-row {
            background-color: transparent;
        }
    }
}

.assessment-role-table {
    .level-label {
        font-weight: bold;
        font-size: 1.1rem;
    }
    .role-label {
        font-size: 0.8rem;
        vertical-align: bottom;
        &.disabled {
            color: grey;
        }
    }
    .select-col {
        width: 30px;
        &.child {
            padding-left: 30px;
        }
    }
    .progress-col {
        width: 20px;
        padding-right: 20px;
    }
    .rc-table-row {
        border: none;
        height: 2.5rem;
        background-color: transparent;
        &.selected-row {
            background-color: transparent;
        }
    }
}

.mapping-widget-row {
    display: flex;
    > div {
        padding-right: 8px;
        &:last-child {
            padding-right: 0px;
        }
        flex: 1;
    }
}

.role-admin-buttons {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin-bottom: 1rem;
    > * {
        margin-bottom: 6px;
    }
}

.employee-list-entry {
    border-bottom: $border;
    padding: 6px;
    margin-bottom: 6px;
    border-radius: 5px;
}

.control-section {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    z-index: $toolbar-zIndex;
    background-color: #fff;
    border-bottom: $border;
    .top-row {
        flex: 1;
        padding-bottom: 1rem;
        > div {
            padding-top: 4rem;
        }
    }
}

.people-container {
    .tab-content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: white;
        border-top: $border;
    }
    .tab-pane {
        &.active {
            height: 100%;
        }
    }
    padding: 0;
    display: flex;
    flex: 1;
    //    height: 100%;
    .org-tree {
        flex: 1;
        flex-grow: 1;
        background-color: white;
        padding: 1rem;
        padding-bottom: 2rem;
        border-right: $border;
        min-width: 280px;
        max-width: 300px;
        * {
            &:focus {
                outline: none;
            }
        }
    }
    .work-area {
        flex: 3;
        flex-grow: 3;
        display: flex;
        flex-direction: column;
        //overflow-y: hidden;
    }
    .talent-area-section {
        h2 {
            font-weight: lighter;
            font-size: 1.1rem;
            margin-bottom: 0.6rem;
        }
        margin-bottom: 1rem;
    }
}

.widget-row {
    display: flex;
    > div {
        padding: 0 6px;
        &:first-child {
            padding-left: 0px;
        }
        &:last-child {
            padding-right: 0px;
        }
        flex: 1;
    }
}

.assessment-notes {
    font-size: 0.8rem;
    max-height: 120px;
    overflow: auto;
}

.flag-bar {
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin: auto;
    .checked {
        color: $primary;
    }
}

.vertical-timeline {
    padding-top: 0 !important;
    .vertical-timeline-element {
        margin: 2rem 0 !important;
    }
    h4 {
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: white;
    }
    p {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
    }
}
