@import "../../scss/variables";

.picker {
    font-size: 0.8rem;
    .picker__menu {
        z-index: $dropdown-zIndex;
        overflow: auto;
    }
    .picker__multi-value {
        padding: .1rem .6rem;
        background-color: #e8deee;
        font-size: 1rem;
        font-weight: bold;
    }
    .picker__control--is-focused {
        box-shadow: none !important;
        border: $border-focus !important;
    }
}

.field-picker {
    font-size: 0.8rem;
    min-width: 260px;
    .field-picker__control--is-focused {
        box-shadow: none !important;
        border: $border !important;
    }
    .field-picker__control {
        border: $border !important;
        &:hover {
            border: $border !important;
        }
    }
    .field-picker__option {
        font-size: 0.8rem;
    }
    .field-picker__group-heading {
        font-size: 0.9rem;
        color: $primary;
    }
}

.trait-or-talent-area-picker {
    z-index: calc($dropdown-zIndex + 1);
    font-size: 0.8rem;
    .trait-or-talent-area-picker__option {
        font-size: 0.8rem;
    }
    .trait-or-talent-area-picker__group-heading {
        font-size: 0.9rem;
        color: $primary;
    }
}
