@import "../../scss/variables";

.switch-label {
    margin-right: 8px;
}

.switch-control {
    > :first-child {
        //box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border: $border;
    }
}
