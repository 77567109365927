@import "../../scss/variables.scss";
.app-tabs {
    .nav {
        border-radius: 0;
        background-color: white;
        box-shadow: none;
        border-bottom: 0;
        .nav-item {
            cursor: pointer;
            .nav-link {
                border-radius: 0;
                padding-top: 12px;
                border: 0;
                &.disabled {
                    color: $disabled;
                }
                &.active {
                    font-weight: normal;
                    text-shadow: 0px 0px 1px $body-color;
                    border-bottom: 4px solid darkgray;
                }
                &:not(.active) {
                    &:hover,
                    &:focus {
                        border: 0;
                        border-bottom: 4px solid darkgray;
                    }
                }
            }
        }
        .nav-link {
            color: $body-color;
        }
    }
}
