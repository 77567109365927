@import "../../scss/variables";

.scatter-point {
    border: 1px solid red;
}

.scatter-tooltip {
    background-color: white;
    border-radius: 10px;
    padding: 6px;
}


.grid-config-box {
    background-color: #e3e3e3;
    border: 1px solid white;
    aspect-ratio: 1/1;
}

.summary-tile {
    box-shadow: $standard-shadow;
    background-color: white;
    margin: 2px;
    border-radius: 5px;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    border: 1px solid #e3e3e3;
    min-height: 0;
    flex: 1 1;
    .info-container {
        background-color: white;
        border-radius: 5px;
    }
    .chart-container {
        background-color: white;
        border-radius: 5px;
    }
}

.talent-map-container {
    .axis {
        font-size: 0.9rem;
        font-weight: bold;
        text-transform: uppercase;
    }
    .y-axis {
        position: relative;
        width: 1em;
        height: 100%;
        text-align: center;
      }
      .y-axis .text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    .talent-map {
        flex: 1;
        flex-grow: 1;
        min-height: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
        .talent-map-container {
            flex: 3;
            display: flex;
            flex-direction: column;
        }
        .talent-map-grid {
            min-height: 0;
            flex: 5;
            display: flex;
            align-items: stretch;
            justify-content: stretch;
            overflow: auto;
            .talent-map-column {
                flex: 1;
                display: flex;
                height: inherit;
                justify-content: stretch;
                flex-direction: column-reverse;
            }
            .grid-segment {
                margin-bottom: 1rem;
                background-color: white;
                margin: 2px;
                border-radius: 5px;
                padding: 4px;
                align-items: stretch;
                display: flex;
                flex-direction: column;
                border: $border;
                min-height: 0;
                .grid-item {
                    flex: 1;
                    display: flex;
                    margin: auto;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: lighter;
                    color: white;
                    &.tiny {
                        border-radius: 0;
                        font-size: 1px;
                    }
                }
                .header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.8rem;
                    font-weight: lighter;
                    margin-bottom: 6px;
                    max-height: 2rem;
                    span {
                        margin-right: 6px;
                    }
                }
                flex: 1;
                .grid-segment-body {
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    .grid-row {
                        flex-grow: 0;
                        flex: 1;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        &.empty {
                            align-items: center;
                            justify-content: center;
                        }
                        .grid-col {
                            flex: 1;
                            display: flex;
                            align-items: stretch;
                            justify-content: stretch;
                            padding: 1px;
                            height: 100%;
                            &.remove-padding {
                                padding: 0.5px;
                            }
                            &.empty {
                                align-items: center;
                                justify-content: center;
                                border-radius: 5px;
                                color: $light-panel-background;
                                border: $border;
                            }
                        }
                    }
                }
            }
        }
    }
}
