@import "../../scss/variables";

.custom-picker-popover {
    .popover {
        max-width: 520px;
    }
}

.custom-field {
    > div {
        flex: 1;
    }
}

.custom-field-display-container {
    .collapse-section {
        border: $border;
        border-top: none;
    }
    .custom-field-display {
        .custom-field-col {
            border: $border;
            &:not(:last-child) {
                border-right: none;
            }
        }
        .custom-field-tile {
            position: relative;
            overflow: hidden;
            .ribbon {
                position: absolute;
                inset: 0 auto auto 0; /* top and left equal to 0 */
                transform-origin: 100% 0; /* OR top right */
                transform: translate(-29.3%) rotate(-45deg);
                box-shadow: 0 0 0 999px;
                clip-path: inset(0 -100%);
            }
            padding: 1rem 1rem;
            height: 100%;
            text-align: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: bold;
            &:not(.blank) {
                background-color: white;
                h6 {
                    font-size: 0.9rem;
                    //color: $primary;
                }
            }
            &.blank {
                font-weight: lighter;
                color: $disabled;
                background-color: $secondary;
            }
        }
    }
}
