@import "./variables";

.btn {
    background-color: transparent;
    color: $body-color;
    &:disabled {
        background-color: transparent;
        color: $disabled;
    }
    text-transform: uppercase;
}

.radio-button {
    text-transform: capitalize;
    color: $gray-300;
    font-weight: bold;
    padding: 3px 0;
    &.selected {
        border-radius: 5px;
        box-shadow: $standard-shadow;
        color: $selected;
    }
    &.disabled {
        color: $disabled;
    }
    &:not(.disabled) {
        cursor: pointer;
        &:hover {
            color: $hover;
        }
    }
}

.my-button {
    position: relative;
    transition-duration: 0.4s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: white;
    color: $body-color;
    &:not(.disabled) {
        &:hover {
            box-shadow: $hover-shadow;
            color: $button;
        }
    }
    &.fill {
        height: 100%;
        width: 100%;
    }
    &.disabled {
        opacity: 0.3;
        cursor: default;
    }
    &.round {
        border-radius: 50%;
        border: $border;
    }
    &.rounded {
        border-radius: 10px;
    }
    &.square {
        border-radius: 0;
        border: none;
    }
    &.flag {
        background-color: transparent;
        border: $border;
    }
    &.icon {
        border: none;
        box-shadow: none;
        background-color: transparent;
        &:hover {
            border: none;
            box-shadow: none;
            background-color: transparent;
        }
    }
    &.outline {
        box-shadow: none;
        border: none;
        background-color: transparent;
        &:not(.disabled) {
            &:hover {
                border: none;
                box-shadow: none;
                background-color: transparent;
            }
        }
        &.checked {
            color: $button;
            filter: brightness(80%);
        }
    }
    &.offset-right {
        position: absolute;
        top: 6px;
        left: calc(50% + 10px);
    }
    &.card-settings {
        position: absolute;
        margin-top: 10px;
        align-self: flex-end;
        width: 30px;
        box-shadow: none;
        border: none;
        background-color: transparent;
        &:not(.disabled) {
            &:hover {
                border: none;
                box-shadow: none;
                background-color: transparent;
            }
        }
        &.checked {
            color: $button;
            filter: brightness(80%);
        }
    }
}

.btn.basic {
    color: $body-color;
    border: $border;
    &:hover {
        background-color: white;
        color: $body-color;
        border-color: transparent;
    }
    &.invert {
        border-color: transparent;
        &:hover {
            background-color: white;
            background-color: $light-panel-background;
        }
    }
}

.btn.nav-bar {
    color: $body-color;
    border: none;
    &.warning {
        background-color: transparent;
        color: $danger;
    }
    &:hover {
        filter: brightness(150%);
    }
}

.text-only {
    border: none !important;
    filter: brightness(75%);
    padding: 0;
    &:not(.disabled):hover {
        color: $hover;
        cursor: pointer;
        background-color: transparent;
        filter: brightness(100%);
    }
    &:focus {
        color: $hover;
        cursor: pointer;
        border: none;
        background-color: transparent;
        font-weight: bold;
        filter: brightness(100%);
    }
}

.btn.pagination-button {
    border: none;
    margin: 0 6px;
    width: 30px;
    height: 30px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $body-color;
    &.selected {
        color: $primary;
        background-color: transparent;
    }
    &:hover {
        color: $primary;
        background-color: transparent;
    }
    &:focus {
        box-shadow: none;
    }
}
