@import "../../scss/variables";

.talent-strength {
    border: $border;
    .ratings-container {
        border: none;
    }
    .talent-area-selector {
        flex: 1;
        min-width: 120px;
        border: $border;
        border-top: none;
        border-right: none;
        .card-body {
            background-color: inherit;
        }
        &:first-child {
            border-left: none;
        }
        &.selected {
            background-color: #e9e9e5;
        }
        &.hovered {
            background-color: #f5f5f5;
        }
    }
}
