.react-joyride__tooltip {
    & div:last-of-type {
        margin-top: 0 !important;
    }
    font-size: 0.9rem !important;
}

.tour-page {
    
}
