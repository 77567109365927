@import '../../scss/variables.scss';

.draggable-board {
    position: relative;
    height: 100%;
    overflow: hidden;
    .card {
        cursor: pointer;
        &:not(.option-card) {
            text-align: left;
            .card-title {
                font-size: 1rem;
            }
            .card-subtitle {
                font-size: 0.9rem;
            }
        }
    }
    .board-options-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: auto; /* Add this line */
        max-height: 100%; /* Add this line */
        .options-header {
            position: sticky;
            top: 0;
            left: 0;
        }
    }
    .board-container {
        flex: 4;
        display: flex;
        flex-direction: column;
        .board-summary {
            display: flex;
            position: sticky;
            background-color: white;
            top: 0px;
            z-index: 10; /* Add this line */
        }
        .board-header {
            position: relative;
            top: 0;     
            z-index: 10; /* Add this line */
        }
        .board {
            flex: 1;
            display: flex;
            flex-direction: column;
            .board-row {
                display: flex;
                flex-direction: row;
                .cell {
                    flex: 1;
                    text-align: left;
                    border: 1px dashed lightgray;
                }
                .drop-zone-cell {
                    flex: 1;
                    text-align: center;
                    border: 1px dashed lightgray;
                }
            }
        }
    }
}
