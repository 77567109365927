// Variable overrides

$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-base: $font-family-sans-serif;

// Layout
$tooltip-zIndex: 200;
$layout-zIndex: 175;
$dropdown-zIndex: 150;
$toolbar-zIndex: 125;
$footer-zIndex: 100;
$overlay-zIndex: 75;

$spacer: 14px;
$gray-300: #7a7d7d;
$sidebar: #3c4b64;

$button: #4878ff;
$primary: #4878ff;
$primary-opaque: rgba(72, 120, 255, 0.1);
$yellow-opaque: rgba(254, 193, 53, 0.1);
$overlay: rgba(0, 0, 0, 0.5);

$bg-hovered: $primary-opaque;

$secondary: #f5f5f5;
$success: #34c25f;
$danger: #d81b60;
$info: #9856c9;
$light: #e3e3e8;
$dark: #262734;
$disabled: #a3abb3;
$highlight-row: #ebf0ff;
$table-cell: #f4f4f7;
$control-background: #edf2f7;

// This is better than using primary to highlight selected stuff
$selected: #3182ce;

// Shadows
$standard-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$focus-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$hover-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

// Colors
$modal-background: #ffffff;
$body-color: #666666;
$sub-title: #a3abb3;
$header-text: #32373b;
$lighter-header-text: mix($header-text, white, 60%);
$panel-background: mix($header-text, white, 6%);
$light-panel-background: mix($header-text, white, 4%);
$light-border: .5px solid rgba(0, 0, 21, 0.125);
$selected-background: #e9e9e5;
$hover-background: #f5f5f5;

//Tables
$cell-background: $light-panel-background;

$good: #34c25f;
$bad: #9b1d20;
$border-color: rgba(0, 0, 21, 0.125);
$border: 1px solid rgba(0, 0, 21, 0.125);
$border-light: .5px solid rgba(0, 0, 21, 0.125);
$border-focus: 1px solid #a0c2dc;
$hover: #1a70ff;
$header: #4878ff;
$distinguished-row: #f5f5f5;
$yellow: #fec135;
$warning: #fec135;
$purple: #9856c9;
$blue: #4878ff;
$icon: #fec135;

// Entities
$talent-board: #7a7d7d;
$talent: #f5cb5c;
$talentArea: #37371f;

// Padding
$control-padding: 0.4rem 0.6rem;

/// DONT REFER TO THESE IN THE APP, USE GENERIC NAMES LIKE ABOVE
/* SCSS HEX */
$blue-green: #3c97baff;
$dark-lava: #423629ff;
$dark-moss-green: #4f5d2fff;
$timberwolf: #ddd5d0ff;
$pale-silver: #cfc0bdff;
$orange-pantone: #fb5607ff;

/* SCSS HSL */
$hsl-blue-green: hsla(197, 51%, 48%, 1);
$hsl-dark-lava: hsla(31, 23%, 21%, 1);
$hsl-dark-moss-green: hsla(78, 33%, 27%, 1);
$hsl-timberwolf: hsla(23, 16%, 84%, 1);
$hsl-pale-silver: hsla(10, 16%, 78%, 1);
$hsl-orange-pantone: hsla(19, 97%, 51%, 1);

/* SCSS RGB */
$rgb-blue-green: rgba(60, 151, 186, 1);
$rgb-dark-lava: rgba(66, 54, 41, 1);
$rgb-dark-moss-green: rgba(79, 93, 47, 1);
$rgb-timberwolf: rgba(221, 213, 208, 1);
$rgb-pale-silver: rgba(207, 192, 189, 1);
$rgb-orange-pantone: rgba(251, 86, 7, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #3c97baff, #423629ff, #4f5d2fff, #ddd5d0ff, #cfc0bdff);
$gradient-right: linear-gradient(90deg, #3c97baff, #423629ff, #4f5d2fff, #ddd5d0ff, #cfc0bdff);
$gradient-bottom: linear-gradient(180deg, #3c97baff, #423629ff, #4f5d2fff, #ddd5d0ff, #cfc0bdff);
$gradient-left: linear-gradient(270deg, #3c97baff, #423629ff, #4f5d2fff, #ddd5d0ff, #cfc0bdff);
$gradient-top-right: linear-gradient(45deg, #3c97baff, #423629ff, #4f5d2fff, #ddd5d0ff, #cfc0bdff);
$gradient-bottom-right: linear-gradient(135deg, #3c97baff, #423629ff, #4f5d2fff, #ddd5d0ff, #cfc0bdff);
$gradient-top-left: linear-gradient(225deg, #3c97baff, #423629ff, #4f5d2fff, #ddd5d0ff, #cfc0bdff);
$gradient-bottom-left: linear-gradient(315deg, #3c97baff, #423629ff, #4f5d2fff, #ddd5d0ff, #cfc0bdff);
$gradient-radial: radial-gradient(#3c97baff, #423629ff, #4f5d2fff, #ddd5d0ff, #cfc0bdff);

// Next design theme
$midnight-green-eagle: #114B5F;