.resizer-right {
   display: flex;
   align-items: center;
   padding-left: 5px; // justify-content: center; 
   &::after {
       content: "";
       display: block;
       width: 3px;
       height: 20px;
       border-left: 1px solid rgba(0, 0, 0, 0.2);
       border-right: 1px solid rgba(0, 0, 0, 0.2);
   }
}