@import "./variables";

.employee-card {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.card-container {
    height: 100%;
    display: flex;
}

.card {
    position: relative;
    margin: 0;
    margin-bottom: 0;
    flex: 1;
    background: #fff;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    .close-button {
        position: absolute;
        font-size: .8rem;
        top: 6px;
        right: 6px;
        cursor: pointer;
    }
}

.card-body {
    h6 {
        font-size: 0.8rem;
    }
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 0.75rem;
}

.card-header {
    font-size: 1.1;
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
        margin-bottom: 0;
    }
}

.card-footer {
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 0.8rem;
}

.card-footer.right-to-left {
    flex-flow: row-reverse;
}
