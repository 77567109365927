@import "../../scss/variables";

.nav-item {
    a {
        color: $body-color;
        &.active {
            font-weight: normal;
            text-shadow: 0px 0px 1px $body-color;
        }
        &.disabled {
            color: $disabled;
        }
    }
}
