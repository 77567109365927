.split-pane {
    min-height: calc(100vh - 71px);
    .split-pane-controls {
        margin: 20px 0;
        color: #ccc;
        .btn {
            padding: 0;
        }
    }
    .split-pane-right {
        background-color: white;
        margin-right: 0;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
    }
}
