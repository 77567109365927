@import "./variables";

$navbarHeight: 60px;

.firebase-emulator-warning {
    //display: none !important;
}

.sign-in-layout {
    background-color: $light-panel-background;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    max-width: unset;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
}

.app-layout {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    max-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
}

.outer-container {
    position: absolute;
    top: 60px;
    bottom: 0px;
    left: 60px;
    right: 0px;
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
}

.scrollable-container {
    position: relative;
    overflow: auto;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: column;
    > div:first-of-type {
        flex: 1;
    }
}

.scrollable {
    overflow: auto !important;
    overflow-x: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}

.scrollable::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.inner-container {
    position: relative;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    max-height: 100%;
    min-width: 0;
    display: flex;
    flex-direction: column;
}

.border-all {
    border: $border;
}

.pro-sidebar {
    z-index: calc($layout-zIndex + 1);
    position: absolute;
    top: 0;
    left: 0;
    .pro-sidebar-inner {
        background-color: $sidebar;
    }
    .logo {
        width: 35px;
        height: 35px;
        margin: 17.5px 12.5px;
    }
    .pro-icon-wrapper {
        background-color: unset !important;
        font-size: 22px !important;
    }
    .pro-inner-item {
        color: #d8d8d8;
        padding: 10px !important;
    }
    .pro-sidebar-header {
        border-bottom: none !important;
    }
    .pro-sidebar-footer {
        text-align: center;
        &.expanded {
            text-align: right;
            margin-right: 10px;
        }
        .pro-menu-item {
            height: 3rem;
        }
    }
    &.collapsed {
        width: 60px !important;
        min-width: 60px !important;
    }
}

.app-navbar {
    position: absolute;
    padding-left: 60px !important;
    top: 0px;
    left: 0px;
    right: 0px;
    height: $navbarHeight;
    background-color: white;
    border-bottom: $border;
    z-index: $layout-zIndex;
    h2 {
        text-transform: capitalize;
        padding-left: 1rem;
    }
    .dropdown-menu {
        //margin-right: 1rem !important;
        background-color: white;
        box-shadow: $standard-shadow;
        z-index: $dropdown-zIndex;
    }
    .assessment-dropdown {
        z-index: calc($layout-zIndex + 1);
        .dropdown-menu {
            min-width: 320px;
            button {
                cursor: default;
                &:hover {
                    background-color: white;
                }
            }
        }
    }
    a {
        text-decoration: none;
        color: $body-color;
    }
    .nav {
        width: 100%;
    }
    .app-navbar-inner-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 1rem;
    }
}

.split-pane {
    .row {
        --bs-gutter-x: 0;
        --bs-gutter-y: 0;
    }
}

.split-pane-right-inner {
    padding-top: 4rem;
    padding-bottom: 1rem;
    height: 100%;
}

.react-confirm-alert {
    max-width: 440px;
    border-radius: 10px;
    overflow: hidden;
    perspective: 1px;
}

.react-confirm-alert-overlay {
    background-color: $overlay;
    z-index: $layout-zIndex;
}

.confirm-alert-container {
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    opacity: 1;
    text-align: center;
    border: $border;
    max-width: 400px;
    h2 {
        margin-bottom: 1rem;
    }
    p {
        margin-bottom: 1rem;
    }
    .buttons {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: center;
        > * {
            margin: 8px;
            width: 140px;
        }
    }
}


.fullpage-tabs {
    &.nav {
        border-radius: 0;
        background-color: white;
        box-shadow: none;
        border-bottom: 0;
        font-size: 18px;
        padding-left: 4px;
        .nav-item {
            margin-right: 20px;
            cursor: pointer;
            .nav-link {
                border-radius: 0;
                padding-top: 12px;
                border: 0;
                background-color: transparent;
                &.disabled {
                    color: $disabled;
                }
                &.active {
                    font-weight: normal;
                    text-shadow: 0px 0px 1px $body-color;
                    //border-bottom: 4px solid #767691;
                    border-bottom: 4px solid $yellow;
                }
                &:not(.active) {
                    &:hover,
                    &:focus {
                        border: 0;
                        border-bottom: 4px solid darkgray;
                    }
                }
            }
        }
        .nav-link {
            color: $body-color;
        }
    }
    + .tab-content {
        background-color: #fafafc;
        .tab-pane {
            padding: 0;
        }
    }
    &.inline {
        background-color: transparent;
        .nav-item {
            .active {
                background-color: transparent;
            }
        }
    }
}

.side-area-header {
    height: $navbarHeight;
    font-weight: lighter;
}
.side-area {
    padding: 0 1rem;
    .info-line {
        display: flex;
        justify-content: space-between;
        h3 {
            margin-bottom: 6px;
        }
        > :first-child {
            flex: 3;
        }
        > :last-child {
            max-width: 20px;
        }
    }
    .side-area-section {
        &:not(:first-child) {
            margin-top: 1rem;
        }
        margin-bottom: 0;
        p {
            margin-bottom: 0;
        }
        h3 {
            font-size: 1.1rem;
            font-weight: lighter;
            border-bottom: $border;
        }
    }
}

.title-box {
    height: 180px;
}

.dashboard-text {
    font-size: 0.8rem;
}

.breadcrumb {
    margin: 0;
    .breadcrumb-item {
        cursor: pointer;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
    }
}

.empty-state-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider {
    margin-bottom: 1rem;
    border-bottom: 1px solid $border;
}

.dashboard-row {
    > div {
        padding: 0 1rem;
        &:first-child {
            padding-left: 0px;
        }
        &:last-child {
            padding-right: 0px;
        }
    }
}

.side-area-footer {
    padding-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    background-color: white;
    border-top: $border;
    position: sticky;
    margin-bottom: 0;
    bottom: 0;
}

.avatar {
    border-radius: 50%;
    padding: 0.3rem;
    border: $border;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: $primary-opaque;
    }
}

.btn-group {
    > * {
        &:not(:first-child) {
            margin-left: 12px;
        }
    }
}

.__react_component_tooltip {
    max-width: 180px;
    text-align: center;
}

.toolbar {
    position: sticky;
    z-index: $toolbar-zIndex;
    background-color: $panel-background;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.2rem;
    border-bottom: $border;
    .justify-end {
        justify-content: flex-end;
        display: flex;
        align-items: center;
    }
    .justify-center {
        justify-content: center;
        display: flex;
        align-items: center;
    }
    .justify-between {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }
    .justify-start {
        justify-content: flex-start;
        display: flex;
        align-items: center;
    }
    .filter {
        display: flex;
        align-items: center;
        margin-right: 12px;
        border: $border;
        border-radius: 5px;
        background-color: white;
    }
    .buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: $border;
        padding: 6px 12px;
        border-radius: 10px;
        background-color: white;
    }
}
