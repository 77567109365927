@import "./variables";

.bg-transparent {
    background-color: transparent !important;
}

.bg-white {
    background-color: white !important;
}

.bg-dark {
    background-color: $panel-background !important;
}

.bg-darkish {
    background-color: $light-panel-background !important;
}

.border-bottom-dashed {
    border-bottom: dashed 1px $border-color;
}

.bg-fun {
    background-color: #deeaee;
}

.bg-distinguished {
    background-color: $distinguished-row;
}

.f-1 {
    flex: 1;
}
.f-2 {
    flex: 2;
}
.f-3 {
    flex: 3;
}
.f-4 {
    flex: 4;
}
.f-5 {
    flex: 5;
}

.pb-6 {
    padding-bottom: 5rem;
}
.pb-8 {
    padding-bottom: 12rem;
}
.min-height-250 {
    min-height: 250px;
}
.min-height-300 {
    min-height: 300px;
}
.min-w-280 {
    min-width: 280px;
}

.text-faded {
    color: $disabled;
}

.text-muted {
    font-size: 0.8rem;
}

.disabled {
    color: $disabled;
}

.box-shadow {
    box-shadow: $standard-shadow;
}

.border-right {
    border-right: $border;
}

.border-none {
    border: none !important;
}

.x-small-text {
    font-size: 0.8rem;
}

.round {
    border-radius: 50%;
    aspect-ratio: 1/1;
}

.c-yellow {
    color: $yellow !important;
}

.c-primary {
    color: $primary !important;
}

.fs-large {
    font-size: 1.3rem !important;
}

.fs-regular {
    font-size: 1rem !important;
}

.fs-small {
    font-size: 0.9rem !important;
}

.fs-tiny {
    font-size: 0.8rem;
}

.c-white {
    color: white !important;
}

.c-light {
    color: $lighter-header-text;
}

.c-danger {
    color: $danger !important;
}

.c-warning {
    color: $warning !important;
}

.c-help {
    color: $info !important;
}

.c-success {
    color: $success !important;
}

.overflow-hide {
    overflow: hidden;
}

.no-wrap {
    white-space: nowrap;
}

.fully-hidden {
    display: none !important;
}

.border-left {
    border-left: $border;
}

.border-x {
    border-left: $border;
    border-right: $border;
}

.border-light-bottom {
    border-bottom: $border-light;
}

.z-top {
    z-index: $layout-zIndex;
}

.faded {
    opacity: 20%;
}

.dashboard-panel {
    min-height: 420px;
}

.aspect-square {
    aspect-ratio: 1/1;
}

.children-mb-3 {
    > * {
        margin-bottom: 1.2rem;
    }
}

.overflow-y {
    overflow-y: show;
}

.overflow-hide {
    overflow: hidden;
}

