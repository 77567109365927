@import '../../../scss/variables';

.mc-number-input {
    .number-input {
        border: none;
        border-radius: 4px;
        border: $light-border;
        margin-bottom: 0.5rem;
        background-color: white !important;
        padding: 0.5rem;
        &:focus {
            outline: none;
        }
        &.large {
            font-size: 3rem;
        }
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
