@import "../../scss/variables";

.floating-action-button-container {
    bottom: 2rem;
    position: fixed;
    right: 2rem;
    z-index: $tooltip-zIndex;

    .floating-action-button {
        background-color: white;
        color: $body-color;
        border-radius: 50%;
        border: 1px solid white;
        display: block;
        margin: 10px auto 0;
        position: relative;
        padding: 0;
        transition: opacity 0.1s ease-out, transform 0.1s ease-out, max-height 0.1s ease-out 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        aspect-ratio: 1/1;
        cursor: pointer;
        &:last-child {
            margin-top: 0;
        }

        &:not(:last-child) {
            opacity: 0;
            max-height: 0;
            margin: 0 auto;
            padding: 0;
            transform: translateY(50px);
        }

        :nth-last-child(1) {
            -webkit-transition-delay: 25ms;
            transition-delay: 25ms;
            background-size: contain;
        }

        :not(:last-child):nth-last-child(2) {
            -webkit-transition-delay: 50ms;
            transition-delay: 20ms;
            background-size: contain;
        }

        :not(:last-child):nth-last-child(3) {
            -webkit-transition-delay: 75ms;
            transition-delay: 40ms;
            background-size: contain;
        }

        :not(:last-child):nth-last-child(4) {
            -webkit-transition-delay: 100ms;
            transition-delay: 60ms;
            background-size: contain;
        }
        &:hover:not(.disabled) {
            background-color: $yellow;
            border-color: white;
            color: white;
            font-weight: bold;
        }
    }

    &:hover {
        background: rgba(245, 245, 245, 0.7);
        .floating-action-button:last-child {
            margin-top: 10px;
        }
        .floating-action-button:not(:last-child):not(.disabled) {
            max-height: 40px;
            margin: 10px auto 0;
            transition: opacity 0.1s ease-out, transform 0.1s ease-out;

            opacity: 1;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            margin: 5px auto 0;
        }
    }
}
