@import "../../scss/variables";

.form-stepper {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: stretch;
    min-height: 0;
    .modal-body {
        overflow: auto;
        min-height: 0;
    }
    .stepper-inner-container {
        flex: 1;
        display: flex;
        align-items: stretch;
        min-height: 0;
    }
    .step-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        flex: 1;
    }
    .step {
        display: flex;
        flex: 2;
        flex-direction: column;
    }
    textarea {
        height: 150px;
    }
    .form-control {
        font-size: 0.9rem;
    }
    .step-body {
        padding: 1rem 2rem;
        flex: 1;
    }
    h5 {
        font-size: 1rem;
    }
    .stepper-nav-items {
        display: flex;
        position: relative;
        flex: 1;
        .stepper-nav-item {
            max-height: 60px;
            display: flex;
            flex: 1;
            justify-content: space-between;
            padding: 0 1.5rem;
            align-items: center;
            font-size: 1.1rem;
            .stepper-nav-item-label {
                text-transform: capitalize;
                font-weight: 600;
                color: #767691;
                line-height: 19px;
                font-size: 14px;
                text-align: center;
                padding: 16px 0;
            }
            &.stepper-nav-item-current {
                background-color: white;
                .stepper-nav-item-label {
                    font-weight: bold;
                    color: #2b376e;
                }
            }
            &.last-step {
                &:not(.stepper-nav-item-current) {
                    background-color: white;
                    border-top: double 1px $primary;
                    border-bottom: double 1px $primary;
                    .stepper-nav-item-label {
                        font-weight: bold;
                        color: $primary;
                    }
                }
                &.stepper-nav-item-current {
                    background-color: $primary;
                    .stepper-nav-item-label {
                        font-weight: bold;
                        color: white;
                    }
                }
            }
        }
    }
}
