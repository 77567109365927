@import "./variables";

.chart-container {
    display: block;
    width: 100%;
}

.inner-chart-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    .inner-chart {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.chart-legend {
    display: flex;
    .chart-legend-item {
        height: 12px;
        width: 12px;
        margin: 2px;
        border-radius: 5px;
    }
}

.chart-label {
    //height: 46px;
    text-align: center;
    width: 100%;
    font-size: 0.8rem;
}

.recharts-tooltip-wrapper {
    border: none !important;
}

.chart-tooltip {
    background-color: white;
    border-radius: 5px;
    border: none !important;
    outline: none !important;
    box-shadow: $standard-shadow !important;
    padding: 0.6rem 1.2rem;
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.empty-round-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: $light-panel-background;
    margin: 1rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: $border;
}

.talent-area-radial-empty-state {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
    width: 75%;
}

/* Center the contents of the bubble chart */
.bubble-chart-container {
    flex: 1;
    position: relative;
    display: flex;
    user-select: none;
    justify-content: center;
}

/* Center text in the circle, and hide any overflow */
.bubble-chart-container .bubble-chart-text .bubble-label {
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 50%;
    will-change: left, top, height, width;
}

/* Show the overflow when hovering over a label */
.bubble-chart-container .bubble-chart-text .bubble-label:hover {
    overflow: visible;
    font-weight: bold;
}

/* Optional, but recommended - reduce the size of text on medium bubbles */
.bubble-chart-container .bubble-chart-text .bubble-label.medium {
    font-size: 0.8em;
}

/* Optional, but recommended - hide the text on small bubbles */
.bubble-chart-container .bubble-chart-text .bubble-label.small {
    font-size: 0;
}
/* Make sure to unhide the text when hovering */
.bubble-chart-container .bubble-chart-text .bubble-label.small:hover {
    font-size: 0.8em;
}
