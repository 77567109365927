@import "../../scss/variables";

.base-table::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}
.base-table::-webkit-scrollbar {
    height: 12px;
    width: 0;
}
.base-table::-webkit-scrollbar-thumb {
    background-color: #999999;
    width: 20px !important;
}

.toolbar {
    position: sticky;
    top: 0;
    background-color: #f0f0f0;
    z-index: 1;
}

.table-container {
    position: relative;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    padding-bottom: 50px;
    overflow: hidden;
}

.base-table {
    overflow-x: auto;
    &.remove-border {
        border: unset !important;
        box-shadow: none !important;
    }
    &:not(.wide-padding) {
        td,
        th {
            &:first-child:not(.rc-table-row-expand-icon-cell) {
                padding-left: 1rem;
            }
            &:last-child {
                padding-right: 1rem;
            }
        }
    }
    &.wide-padding {
        td,
        th {
            &:first-child {
                padding-left: 2rem;
            }
            &:last-child {
                padding-right: 2rem;
            }
        }
    }
    tr {
        max-height: 80px;
        border-top: $border-light;
        &.disabled {
            background-color: rgba(255, 0, 0, 0.1);
        }
        td {
            max-height: 80px;
            padding: 6px 4px;
        }
    }
    .awaiting {
        background-color: transparent !important;
        opacity: 0.5;
        color: $disabled !important;
    }
    .rc-table-content {
        table {
            width: 100%;
        }
    }
    .rc-table-thead {
        position: sticky;
        top: 0;
        background-color: #f7f7f7;
        z-index: 1;
        border-bottom: $border;
        tr {
            border-bottom: $border;
            th {
                padding: 16px 6px;
                border-bottom: $border;
                text-transform: uppercase;
                font-size: 0.9rem;
            }
        }
    }
    th.rc-table-cell {
        white-space: nowrap;
    }
    .rc-table-tbody {
        overflow-y: auto;
        border-top: $border;
        top: 0;
        position: relative;
    }
    .rc-table-row {
        border-bottom: $border;
        .show-only-on-selected-row {
            visibility: hidden;
        }
        .expand-icon {
            height: 40px;
            display: flex;
        }
    }

    .rc-table-row-expand-icon-cell {
        width: 60px;
    }

    .selected-row {
        background-color: $highlight-row !important;
        border: $border;
        .show-only-on-selected-row {
            visibility: visible;
        }
    }

    .cell-inner-container {
        padding: 8px 4px;
        &.shaded {
            border-radius: 5px;
            background-color: $cell-background;
        }
        &.align-end {
            display: flex;
            justify-content: flex-end;
        }
    }

    .cell-content {
        overflow-x: hidden;
        white-space: nowrap;
    }

    .rc-table-placeholder {
        td {
            padding: 1rem;
        }
    }
}

.pagination {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f0f0f0;
    z-index: 1;
}
