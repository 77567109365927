@import "../../scss/variables";


.new-text-editor {
    border-radius: 2px;
    padding: 0.8rem 1rem;
    a {
        
    }
}

.rich-text-display {
    ul {
        list-style-type: disc;
        li {
            list-style-type: disc;
        }
    }
}


.text-editor-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem !important;
    .rdw-editor-toolbar {
        display: flex;
        margin-bottom: 6px;
        > div {
            display: flex;
            > div {
                margin: 2px 6px;
                cursor: pointer;
            }
        }
        button {
            border: none;
            background-color: transparent;
            &:hover {
                color: $selected;
                &:disabled {
                    color: $disabled;
                }
            }
            &:first-child {
                margin-right: 6px;
            }
        }
        .rdw-link-wrapper {
            position: relative;
        }
        .rdw-link-modal {
            position: absolute;
            font-size: 0.9rem;
            top: 35px;
            left: 20px;
            display: flex;
            width: 280px;
            flex-direction: column;
            border: 1px solid #f1f1f1;
            padding: 15px;
            border-radius: 2px;
            z-index: $overlay-zIndex;
            background: white;
            box-shadow: 3px 3px 5px #bfbdbd;
            input {
                margin-bottom: 12px;
                border: $border;
            }
            span {
                margin-left: 4px;
                font-size: 0.8rem;
            }
        }
    }

    .rdw-editor-main {
        flex: 1;
        padding: 12px 18px;
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
        ul {
            list-style-type: disc;
            li {
                list-style-type: disc;
            }
        }
    }
}