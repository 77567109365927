.report-modal .modal-content {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
}

.report-modal .modal-body {
    overflow-y: auto;
    flex-grow: 1;
}
