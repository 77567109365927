@import "./variables";

.modal-dialog {
    .modal-content {
        border: 0;
        .modal-header {
            color: #040a33;
            font-weight: bold;
            align-items: center;
            padding: 1rem 1.5rem;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 1rem;
            .modal-title {
                font-weight: bold;
                color: #2b376e;
                font-size: 16px;
                font-weight: 600;
            }
            .close {
                color: black;
            }
        }
        .modal-body {
            padding: 0;
            > .nav-tabs {
                border-radius: 0;
                padding: 0.25rem 0.875rem;
            }
            .modal-body-content {
                padding: 1.5rem;
                h6 {
                    padding-bottom: 0.75rem;
                    color: black;
                }
            }
        }
        .modal-footer {
            max-height: 80px;
            border-top: 1px solid #e8e8e8;
            justify-content: flex-end;
        }
    }
    &.timeoff-modal {
        margin-top: -70px;
    }
    &.modal-split-pane {
        .modal-header {
            border: none;
        }
        .modal-body {
            display: flex;
            > div {
                padding: 16px 32px;
                .modal-body-content {
                    padding: 0;
                }
            }
            .chart-wrapper {
                border-radius: 4px;
                padding: 5px 10px 30px;
                height: 200px;
                .chart-wrapper-inner {
                    height: 100px;
                    padding: 0 40px;
                }
            }
        }
    }
    .modal-split-pane-left {
        flex: 5;
    }
    .modal-split-pane-right {
        h6 {
            font-weight: lighter;
        }
        p {
            font-size: 0.9rem;
        }
        flex: 3;
        padding: 1rem .8rem;
    }
}