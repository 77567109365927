.breakdown-table {
    width: 100%;
    border-collapse: collapse;
    tr {
        width: 100%;
        display: flex;
    }
    th,
    td {
        padding: 0.5rem;
        text-align: left;
        flex: 1;
        &:first-child {
            flex: 2;
        }
    }
    thead {
        display: flex;
    }
    thead th {
        position: sticky;
        top: 0;
    }
    tbody {
        display: block;
        overflow-y: scroll;
        height: 300px;
    }
    tbody tr:nth-child(even) {
        background-color: #f2f2f2;
    }
}
