@import "./variables";

form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
        margin-top: 3rem;
    }
    h3:first-of-type {
        margin-top: 0;
    }
    label {
        text-transform: capitalize;
        font-weight: bold;
    }
}

.trait-form {
    .form-label {
        font-size: 1.1rem;
        text-transform: uppercase;
    }
}

.field-error {
    color: #dc3545 !important;
    display: block !important;
}

.form-label {
    text-transform: capitalize;
    color: $header-text;
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
}

.bullet-points {
    li {
        list-style-type: disc;
        margin-bottom: 0.4rem;
    }
}

.form-control {
    &:focus {
        border: $border-focus;
        box-shadow: none;
    }
}

.radio-button-row {
    display: flex;
    > * {
        flex: 1;
        margin: 0 8px;
        &:first-child {
            margin-left: 0;
        }
    }
}

.form-check {
    height: 2.5rem;
    display: flex;
    align-items: center;
}

.form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 1rem;
    vertical-align: middle;
}

input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    transform: scale(2);
}

input[type="radio"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: $yellow;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    transform: scale(2);
}

.text-area {
    height: 150px !important;
}

.form-group {
    vertical-align: middle;
    label {
        margin-bottom: 6px;
    }
}

.form-tabs {
    li {
        padding: 0.85rem 1.5rem;
        border-bottom: solid 1px #f8f8f8;
        display: block;
        font-size: 1.1rem;
        cursor: pointer;
    }
    li:hover {
        background-color: #f9f9fc;
    }
    .selected-tab {
        font-weight: bold;
    }
    .tab-list {
        padding: 0;
    }
    .tab-pages {
        padding: 0.85rem 1.5rem;
    }
}

.form-container {
    min-height: 60vh;
}

.form-footer {
    padding-bottom: 1rem;
}

.step.choice {
    display: flex;
    flex-direction: column;
    align-items: center;
}