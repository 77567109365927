@import "../../../../scss/variables";

.org-table {
    .filter {
        margin-right: 12px;
        border: $border;
        padding: 3px 12px;
        border-radius: 5px;
        background-color: white;
    }
}

.org-chart-selected-employee {
    h6, h5 {
        color: #7b7b7b;
    }
    position: absolute;
    background-color: $light-panel-background;
    border-radius: 5px;
    border: $border;
    padding: 1rem;
    top: 20px;
    left: 100px;
    z-index: 70;
}

.org-chart-container {
    position: relative;
    overflow: hidden;
    ul:empty {
        display: none;
    }
    .org-chart-inner > ul:first-child {
        > li:first-child {
            padding: 0 6rem;
            padding-top: 0;
            padding-bottom: 6rem;
        }
    }
    .org-chart-inner {
        > ul {
            > li {
                > ul {
                    padding-top: 6px;
                }
            }
        }
    }
    .display-name {
        z-index: calc($overlay-zIndex + 1);
        background-color: inherit;
        position: absolute;
        top: 0px;
        left: 12px;
    }
}

.tree-start-point {
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: $primary;
    margin: auto;
}
.org-chart-item {
    margin: auto;
    box-shadow: none;
    &.root-item {
        display: none;
    }
    .root-role {
        border-radius: 10px;
        padding: 6px;
        margin: auto;
    }
    .org-chart-item-body {
        display: inline-block;
        h5 {
            font-size: 0.9rem;
        }
        &.selected {
            .rating-progress-indicator {
                box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px !important;
            }
        }
    }
    .org-chart-item-footer {
        cursor: pointer;
        position: relative;
        color: $body-color;
        padding: 0.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .org-chart-item-footer:empty {
        display: none;
    }
    .btn {
        font-size: 1rem;
    }
}

.rating-progress-indicator {
    margin: auto;
    position: relative;
    border-radius: 50%;
    .indicator-child-container {
        background-color: transparent;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 4px;
        border-radius: 50%;
        .indicator-child {
            border-radius: 50%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            &.skipped {
                opacity: 50%;
            }
        }
        &.no-children {
            border: 4px white solid;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &.root-role-indicator {
        background-color: #d6d6d6;
    }
    &:not(.root-role-indicator) {
        background-color: #d6d6d6;
        &.in-progress {
            background-color: $yellow;
        }
        &.needs-user {
            background-color: $danger;
        }
    }
}

.org-toolbar {
    border-top: none !important;
}

.not-assessed {
    opacity: 0.4;
}

.org-chart-bar-container {
    position: absolute;
    top: 20px;
    z-index: calc($overlay-zIndex + 1);
    &.left {
        left: 2rem;
    }
    &.right {
        right: 2rem;
    }
    .org-chart-bar {
        display: flex;
        flex-direction: column;
        background: rgba(245, 245, 245, 0.7);
        box-shadow: 0 0 10px $light-panel-background;
        width: 36px;
        > div {
            aspect-ratio: 1/1;
            margin-bottom: 6px;
        }
    }
}
