@import "../../scss/variables";

.sign-in-layout {
    img {
        height: 100%;
        width: auto;
        object-fit:scale-down;
        object-position: center;
    }
    .sign-in-image {
        padding: 1rem;
        margin: auto;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        &:not(.confirm) {
            aspect-ratio: 1/1;
        }
        &.request {
            background-color: $primary;
        }
        &.sent {
            background-color: $primary;
        }
        &.confirm {
            background-color: transparent;
        }
    }
    h1 {
        margin: auto;
        margin-bottom: 0.5rem;
    }
    p {
        margin: auto;
        padding: 0 2rem;
        max-width: 540px;
        margin-bottom: 0.8rem;
    }
    .form-section {
        max-width: 350px;
    }
}