// Here you can add other styles
@import "./variables";

.firebase-emulator-warning {
    //display: none;
}

.responsive-container {
    position: relative;
}

body {
    color: $body-color;
    font-family: $font-family-base;
}

h1,
h2,
h3,
h4 {
    color: $header-text;
}

h5,
h6 {
    text-transform: uppercase;
}

h3 {
    line-height: 2.4rem;
}

// Card Titles
h6 {
    //  font-size: 1.1rem;
    font-weight: bold;
}

h6.alert {
    margin: 0;
}

hr {
    z-index: 0;
}

.disabled-tab {
    color: $disabled;
}

.padded-page {
    padding: 1rem 2rem;
}

.done {
    color: $success;
}

.action-done {
    color: white;
    background-color: $success !important;
    font-weight: bold;
}

.side-area-inner-container {
    padding: 1rem;
}

.yellow {
    color: $yellow;
}

.row-p1 > div {
    padding: 0;
    padding-right: 4px;
}

.row-p1 > div:last-child {
    padding-right: 0;
}

.row-p2 > div {
    padding: 0;
    padding-right: 12px;
}

.row-p2 > div:last-child {
    padding-right: 0;
}

.real-list {
    li {
        list-style-type: disc;
        display: list-item;
        list-style-position: inside;
    }
}

.breadcrumb {
    margin-bottom: 0 !important;
}

.app-tooltip {
    display: flex;
    flex-direction: column;
}

.hide-scroll-bars {
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
}

.hide-scroll-bars::-webkit-scrollbar {
    display: none;
}

.panel {
    padding: 0.75rem;
    margin-bottom: 4px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border: none;
    height: 100%;
    &.remove-padding {
        padding: 0;
        margin-bottom: 0;
    }
}

.dropdown-menu {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.info-panel {
    padding: 0.75rem;
    border-radius: 10px;
    margin-bottom: 4px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border: none;
}

.full-height-panel {
    height: 100%;
}

.side-panel {
    background-color: #f4f4f7;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.hidden-delay {
    visibility: hidden;
    transition: visibility 500ms linear;
}

.clickable {
    cursor: pointer;
    > * {
        cursor: pointer !important;
    }
}

.link {
    cursor: pointer;
    color: $hover;
}

.link:hover {
    text-decoration: underline;
}

h6.clickable:hover {
    color: $hover;
}

.not-clickable:hover {
    background-color: transparent;
}

.large-font {
    font-size: x-large;
}

.make-circular {
    border: white solid 1px;
    border-radius: 50%;
}

.rotate0 {
    transform: rotate(0deg);
}

.talent-board-label {
    background-color: white;
    border: 1px solid $talent-board;
    color: $talent-board;
    display: inline-block;
    padding: 0.3rem;
    font-size: 80%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    margin: 2px;
    border-radius: 0.2rem;
}

.sticky-tab-bar {
    position: sticky;
    top: 0;
}

.no-scroll {
    overflow: none !important;
}

.page-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
}

.dropdown-item {
    text-transform: capitalize;
}

.no-column-padding > div {
    padding: 0;
}

.filter-switch label {
    cursor: pointer;
}
.filter-switch-item input:checked + label {
    color: inherit;
}
.filter-switch-item input:not(:checked) + label {
    --bg-opacity: 0;
    box-shadow: none;
}

.small-text {
    font-size: 0.9em;
}

.full-screen-container {
    height: 100%;
}

.full-height-row {
    height: 100%;
}

.scope-icon {
    .flagged {
        color: $primary;
    }
}

.flag-icon {
    color: $light;
    z-index: $overlay-zIndex;
    > * {
        color: inherit;
    }
    &.top-right {
        position: absolute;
        top: 16px;
        right: 16px;
    }
    &.top-left {
        position: absolute;
        top: 16px;
        left: 16px;
    }
    &.bottom-left {
        position: absolute;
        bottom: 10px;
        left: 16px;
    }
    &.top-talent {
        &.flag-on {
            color: $yellow;
        }
    }
    &.flight-risk {
        &.flag-on {
            color: $danger;
        }
    }
    &.succession-plan {
        &.flag-on {
            color: $info;
        }
    }
    &.success {
        color: $success;
    }
    &.warning {
        color: $warning;
    }
    &.info {
        color: $info;
    }
}

.side-area-label {
    font-size: 1rem;
    margin-bottom: 0.8rem;
}

.tooltip-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.action-box {
    h6 {
        margin-bottom: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px $border solid;
}

.tile {
    background-color: $panel-background;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.pill {
    font-size: 0.9rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    min-width: 120px;
    padding: 4px 12px;
    border-radius: 5px;
    &.trait-scope-pill {
        color: $body-color;
        &.boards {
            background-color: #e8deee;
        }
        &:not(.boards) {
            background-color: #dbeddb;
        }
    }
    &.outline {
        border: $border;
        border-radius: 2px;
    }
    &.complete {
        background-color: $success;
        color: white !important;
    }
    &.active {
        background-color: $success !important;
        color: white !important;
    }
    &.inactive {
        background-color: $yellow !important;
    }
    &.unrated {
        background-color: $disabled !important;
        color: white !important;
    }
}

.rating-badge {
    width: 28px;
    font-size: 0.8rem;
    padding: 4px;
    text-align: center;
    &.rated {
        border-radius: 50%;
        aspect-ratio: 1/1;
        border: $border;
    }
}

.badge.count-badge {
    z-index: $overlay-zIndex;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    aspect-ratio: 1/1;
    color: white;
    background-color: $info !important;
    &.single {
        width: 18px;
        height: 18px;
        top: -8px;
        right: -8px;
    }
    &.double {
        width: 22px;
        height: 22px;
        top: -10px;
        right: -10px;
    }
    &.triple {
        width: 26px;
        height: 26px;
        top: -12px;
        right: -12px;
    }
}
