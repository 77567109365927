.word-cloud {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    padding: 1rem;
    .word-container {
        display: flex;
        align-items: center;
        .word {
            flex: 1;
            padding: 0rem 0.4rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
